import Model from './Model'
import ProductStock from './ProductStock'

export default class Product extends Model {
  resource () {
    return 'products'
  }

  stocks () {
    return this.hasMany(ProductStock)
  }
}
