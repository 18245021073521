<template lang="pug">
  div
    el-alert(
      type="error"
      title="Bu raporu listeleme yetkiniz yok"
      v-if="!can('reports.stock.counts')"
      show-icon
      :closable="false"
      center
    )
    el-card(body-style="padding:0" v-if="can('reports.stock')")
      div(slot="header")
        el-form(inline)
          el-form-item(label="Şube / Depo")
            el-select(
              v-model="filters.warehouses"
              filterable
              clearable
              multiple
              style="width: 100%"
            )
              el-option-group(label="Şubeler")
                el-option(
                  v-for="s in $store.state.branches"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                )
              el-option-group(label="Depolar")
                el-option(
                  v-for="s in $store.state.warehouses"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                )

          el-form-item(label="Tedarikçi")
            el-select(v-model="filters.suppliers" filterable multiple clearable default-first-option)
              el-option(v-for="s in suppliers" :key="s.id" :value="s.id" :label="s.name")
          el-form-item(label="Kategori")
            el-select(v-model="filters.categories" filterable multiple clearable default-first-option)
              el-option(v-for="c in categories" :key="c.id" :value="c.id" :label="c.name")
          el-form-item
            el-button-group
              el-button(@click="fetch(false)" icon="el-icon-ticket" :loading="fetching") Getir
              el-button(@click="fetch(true)" icon="el-icon-download" :loading="downloading") Excel

      el-input(placeholder="Ürün adı, tedarikçi, kategori ismi girerek arama yapınız" v-model="search" @input="fetch(false)")
        i.el-input__icon.el-icon-search(slot="prefix")
      el-table.border-top(
        :data="report"
        v-loading="fetching"
        size="mini"
        :height="$store.state.windowHeight-194"
      )
        el-table-column(type="index" width="40px" class-name="text-small text-muted" align="right" label="#")
        el-table-column(prop="id" label="Kod" sortable width="110px" align="center" class-name="text-bold" show-overflow-tooltip)
        el-table-column(prop="oldid" label="Eski Kod" sortable width="110px" align="center" class-name="text-bold" show-overflow-tooltip)
          template(v-slot="props"): span.text-danger {{ props.row.oldid }}
        el-table-column(prop="name" label="Ürün" sortable class-name="text-bold" show-overflow-tooltip)
        el-table-column(prop="supplier" label="Tedarikçi" sortable show-overflow-tooltip)
        el-table-column(label="MİKTARLAR" align="center")
          el-table-column(prop="total" label="Toplam" sortable align="right")
            template(v-slot="props") {{ props.row.total }} {{ props.row.unit }}
          el-table-column(prop="sold" label="Satılan" sortable align="right" class-name="text-danger")
            template(v-slot="props") {{ props.row.sold }} {{ props.row.unit }}
          el-table-column(prop="available" label="Kalan" sortable align="right")
            template(v-slot="props") {{ props.row.available }} {{ props.row.unit }}
          el-table-column(prop="returned" label="İade" sortable align="right" class-name="text-danger")
            template(v-slot="props") {{ props.row.returned }} {{ props.row.unit }}
</template>
<script>
import Product from '@/models/Product'
import ProductCategory from '@/models/ProductCategory'
import Company from '@/models/Company'

export default {
  name: 'stock',
  data(){
    return {
      fetching: false,
      downloading: false,
      categories: [],
      suppliers: [],
      branches: [],
      warehouses: [],
      report: [],
      filters: {
        categories: [],
        suppliers: [],
        warehouses: []
      },
      selected: [],
      search: ''
    }
  },
  async mounted(){
    if(this.can('reports.stock.counts')){
      this.fetch(false)
      this.suppliers = await Company.select('id', 'name').where('type', 'supplier').get()
      this.categories = await ProductCategory.select('id', 'name').get()
    }
  },
  methods: {
    async fetch(excel){
      if(!excel){
        this.fetching = true
      }else{
        this.downloading = true
      }
      try{
        let data = await Product.custom('reports/stockcount')
          .params({ ...this.filters, search: this.search, excel })
          .get()
        if(!excel){
          this.report = data
        }else{
          window.location.href = data[0].url;
          this.downloading = false
        }
        this.fetching = false
      }catch(e){
        this.fetching = false
        this.$message.error('Sunucu hatası: ' + e.message)
      }
    }
  }
}
</script>
<style>
#name, #price { line-height: 3.5mm } .pb { display: block; page-break-after: always!important; }
#stock div.el-input-group__append div.el-input--suffix input.el-input__inner {
  width: 78px;
}

@media screen {
  #printarea{
    visibility: hidden;
    display: none;
  }
}

</style>
